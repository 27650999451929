.web-Logo {
  align-items: center;
  align-self: center;
  border-bottom: 1px solid #e4e5e6;
  display: flex;
  justify-content: center;
  min-height: 100px;
  width: inherit;
}

.web-Logo-img {
  margin: 24px;
  max-width: -webkit-fill-available;
  object-fit: scale-down;
}

@media only screen and (max-width: 900px) {
  .web-Logo-img {
    align-items: start;
    max-height: 60px;
    max-width: 180px;
  }
}
